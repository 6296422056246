import { FC } from 'react';

import { ArrowIcon } from 'assets/icons';
import { useAppSelector } from 'common/hooks';
import { LazyImage } from 'elements/LazyImage/LazyImage';
import ProductImage from 'elements/ProductImage/ProductImage';
import { amountRange } from 'modules/ProductCard/helper/amountRange';
import { PRODUCT_DETAILS } from 'routes';
import { supplierSelector } from 'store/reducers/productSlice';
import { IProduct } from 'store/reducers/productSlice/types';
import { SimpleLink, Stars } from 'ui-kit';

import style from './ProductCardFull.module.scss';

interface IProductCardFull {
  product: IProduct;
}

export const ProductCardFull: FC<IProductCardFull> = ({ product }): JSX.Element => {
  const {
    id,
    name,
    grade_average,
    is_favorite,
    product_variation_price_from,
    product_variation_price_to,
    images,
    min_quantity,
    parent_category_name,
    category_name,
    review_count,
    completed_orders_count,
    company_name,
  } = product;

  const supplier = useAppSelector(supplierSelector); // TODO нужно, чтобы бэк в product возвращал также инфу о supplier
  const pathToProduct = `${PRODUCT_DETAILS}/${id}`;
  const image_url = images && Object.values(images[0]?.image_url)[0];

  return (
    <div className={style.card_full}>
      <SimpleLink to={pathToProduct} tabIndex={-1}>
        <ProductImage
          imageUrl={image_url || ''}
          name={name}
          productId={id}
          isFavorite={is_favorite}
          className={style.card_image}
        />
      </SimpleLink>
      <div className={style.card_info}>
        <SimpleLink to={pathToProduct} className={style.name} tabIndex={-1}>
          {name}
        </SimpleLink>
        <div className={style.categories}>
          <p className={style.category}>
            <span>Clothes for {parent_category_name}</span>
            <span>{category_name}</span>
          </p>
        </div>
        <div className={style.price}>
          <p className={style.amount}>
            {amountRange(product_variation_price_from, product_variation_price_to)}/pc
          </p>
          <span className={style.rating}>{`/from ${min_quantity} pcs`}</span>
        </div>

        <div>
          <div className={style.reviews_info}>
            <Stars reward={grade_average} />
            <span className={style.reviews}>/ {review_count} reviews</span>
          </div>
        </div>

        <div className={style.orders_container}>
          <div className={style.supplier_wrapper}>
            <div className={style.supplier_logo}>
              <LazyImage
                className={style.supplier_logo}
                type="stub_logo"
                src={supplier?.company.logo_url || ''}
              />
            </div>

            <div className={style.suppler_name_container}>
              <p className={style.supplier_name}>{company_name}</p>
              <ArrowIcon className={style.supplier_arrow} />
            </div>
            <p className={style.suppler_details}>
              1 Years : 1 Deals : On-time delivery 1%
              {/* TODO Supplier info from backend */}
            </p>
          </div>
          <div className={style.orders}>{completed_orders_count} Orders</div>
        </div>
      </div>
    </div>
  );
};
