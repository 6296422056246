import React, { ReactElement } from 'react';

import { ProductsCarouselByCategory } from './ProductsCarouselByCategory/ProductsCarouselByCategory';

import { ImagesBlock, StatusProduct, SubscriptionAndContacts } from '.';

import { useAppSelector } from 'common/hooks';
import { LoadingStatusEnum } from 'common/types';
import { loadingProductsSelector } from 'store/reducers/productSlice/selectors';
import { ButtonQuestion, LoaderLinear } from 'ui-kit';

import style from './MainPage.module.scss';

export const MainPage = (): ReactElement => {
  const isLoadingProducts = useAppSelector(loadingProductsSelector);
  const categories = useAppSelector(state => state.common.categories);

  return (
    <div>
      <ImagesBlock className={style.images_block} />

      <div className={style.status_container}>
        <StatusProduct />
      </div>

      <div className={style.container}>
        <div className={style.main_sliders}>
          {isLoadingProducts === LoadingStatusEnum.Loading && <LoaderLinear />}
          {categories.map((el, index) => {
            return (
              <ProductsCarouselByCategory
                categories={categories}
                category={el}
                currentEl={index}
                key={el.id}
              />
            );
          })}
        </div>
        <ButtonQuestion />
      </div>

      <SubscriptionAndContacts />
    </div>
  );
};
