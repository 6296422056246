import React, { FC, ReactElement, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { SelectedViewEnum } from 'common/types';
import { SkeletonProductCard, ViewMoreProductsLink } from 'elements';
import { ProductCard, ProductsPreview } from 'modules';
import { ICategoryResponse } from 'services/common/common.serviceTypes';
import {
  getProductsCompilationAC,
  productsCompilationSelector,
  resetProductsCompilationAC,
  sortFieldSelector,
} from 'store/reducers/productSlice';

interface IProductsCarouselByCategory {
  category: ICategoryResponse;
  categories: ICategoryResponse[];
  currentEl: number;
}

export const ProductsCarouselByCategory: FC<IProductsCarouselByCategory> = ({
  category,
  categories,
  currentEl,
}): ReactElement => {
  const dispatch = useAppDispatch();
  const sortField = useAppSelector(sortFieldSelector);
  const products = useAppSelector(productsCompilationSelector);
  const productsCount = 20;
  const selectedView = SelectedViewEnum.GRID;

  useEffect(() => {
    dispatch(resetProductsCompilationAC());
    dispatch(
      getProductsCompilationAC({
        offset: 0,
        limit: productsCount,
        category_id: category.id.toString(),
        ascending: false,
        query: '',
        sort: sortField,
      }),
    );
  }, [sortField]);

  // eslint-disable-next-line array-callback-return
  const productsFilteredByCategory = products.filter(item => {
    if (categories.length !== currentEl + 1) {
      if (
        item.category_id >= categories[currentEl].id &&
        item.category_id < categories[currentEl + 1].id
      ) {
        return item;
      }
    } else if (categories.length === currentEl + 1) {
      if (item.category_id >= categories[currentEl].id) {
        return item;
      }
    }
  });

  const productCardSkeleton = Array.from({
    length: productsCount - productsFilteredByCategory.length,
  }).map((el, i) => <SkeletonProductCard key={i} selectedView={selectedView} />);

  const productsView = productsFilteredByCategory?.map((product, index) => {
    return <ProductCard key={index} product={product} />;
  });

  return (
    <ProductsPreview title={category.name}>
      {productsView}
      {productCardSkeleton}
      <ViewMoreProductsLink />
    </ProductsPreview>
  );
};
