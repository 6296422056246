import React, { FC, useEffect, useState } from 'react';

import { useAppSelector } from 'common/hooks';
import { useAppDispatch } from 'common/hooks/useAppDispatch';
import { FilterTag } from 'pages/general-pages/ProductListPage/ProductFilter/FilterTag/FilterTag';
import { getAllBrandsSelector } from 'store/reducers/productSlice/selectors';
import { getAllBrands } from 'store/reducers/productSlice/thunks';
import { Search } from 'ui-kit/Search/Search';

import style from './Brand.module.scss';

interface IBrandProps {
  selectedBrands: string[];
  onSelectBrandsChange: (brands: string[]) => void;
}

export const Brand: FC<IBrandProps> = ({ selectedBrands, onSelectBrandsChange }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllBrands());
  }, [dispatch]);

  const brandNameTags = useAppSelector(getAllBrandsSelector);

  const initialBrandsToDisplay = brandNameTags.slice(0, 6).map(brand => brand.name);
  const moreBrandsToDisplay = brandNameTags.slice(0, 25).map(brand => brand.name);
  const allBrandsToDisplay = brandNameTags.map(brand => brand.name);

  const [brandsToShow, setBrandsToShow] = useState<string[]>(initialBrandsToDisplay);
  const [buttonText, setButtonText] = useState<
    'See More' | 'See All Brands' | 'See Less'
  >('See More');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSelectBrands = (brandName: string): void => {
    const updatedBrands = selectedBrands.includes(brandName)
      ? selectedBrands.filter(brand => brand !== brandName)
      : [...selectedBrands, brandName];

    onSelectBrandsChange(updatedBrands);
  };

  const handleButtonClick = (): void => {
    if (buttonText === 'See More') {
      setBrandsToShow(moreBrandsToDisplay);
      setButtonText('See All Brands');
    } else if (buttonText === 'See All Brands') {
      setBrandsToShow(allBrandsToDisplay);
      setButtonText('See Less');
    } else {
      setBrandsToShow(initialBrandsToDisplay);
      setButtonText('See More');
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const searchItem = e.target.value;

    setSearchTerm(searchItem);
    const filteredBrands = allBrandsToDisplay.filter(brand =>
      brand.toLowerCase().includes(searchItem.toLowerCase()),
    );

    setBrandsToShow(filteredBrands);
  };

  return (
    <div className={style.brand}>
      <p className={style.title}>Brand</p>
      <div className={style.container}>
        <Search placeholder="Search" value={searchTerm} onChange={handleSearch} />
        <div
          className={
            brandsToShow.length ? style.all_tags_container : style.first_tags_container
          }
        >
          {searchTerm === '' && buttonText === 'See More'
            ? initialBrandsToDisplay.map(brandName => (
                <FilterTag
                  key={brandName}
                  title={brandName}
                  selected={selectedBrands.includes(brandName)}
                  onClick={() => handleSelectBrands(brandName)}
                />
              ))
            : brandsToShow.map(brandName => (
                <FilterTag
                  key={brandName}
                  title={brandName}
                  selected={selectedBrands.includes(brandName)}
                  onClick={() => handleSelectBrands(brandName)}
                />
              ))}
        </div>
        {searchTerm === '' && (
          <button
            type="button"
            onClick={handleButtonClick}
            className={style.show_all_brands}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};
