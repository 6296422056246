import { useEffect, useMemo } from 'react';

import { useGetSearchParams } from './common/hoocks/useGetSearchParams';
import { ProductHeader } from './ProductHeader/ProductHeader';
import { ProductsList } from './ProductsList/ProductsList';

import { useAppDispatch, useAppSelector, useDebounce } from 'common/hooks';
import {
  getSupplierProductsListAC,
  hasChangedSelector,
} from 'store/reducers/supplier/product';

import style from './SupplierProducts.module.scss';

export const SupplierProducts = (): JSX.Element => {
  const { status, sale, categoryIds, limit, page, sortField, sortBy, query } =
    useGetSearchParams();
  const dispatch = useAppDispatch();
  const hasChanged = useAppSelector(hasChangedSelector);
  const searchValue = useDebounce(query!);
  const offset = (page - 1) * limit;
  const categoryIdsString = useMemo(() => categoryIds.join(', '), [categoryIds]);
  const categoryIdsStringDebounced = useDebounce(categoryIdsString);
  const categoryIdsStringFinal =
    categoryIds.length > 0 ? categoryIdsStringDebounced : categoryIdsString;

  useEffect(() => {
    dispatch(
      getSupplierProductsListAC({
        offset,
        limit,
        ascending: sortBy,
        category_ids: categoryIdsStringFinal,
        query: searchValue?.length ? searchValue : '',
        sort: sortField,
        on_sale: sale ?? true,
        is_active: status ?? true,
      }),
    );
  }, [
    offset,
    page,
    sortField,
    searchValue,
    sale,
    limit,
    sortBy,
    status,
    hasChanged,
    categoryIdsStringFinal,
    dispatch,
  ]);

  return (
    <section className={style.wrapper}>
      <div className={style.content_container}>
        <ProductHeader />
        <ProductsList />
      </div>
    </section>
  );
};
