import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  IImage,
  IProductReviewsGrades,
  IResponseGetProductCardId,
  IResponseGetProductReviews,
} from './types';

import { getErrorMessageFromServer } from 'common/utils/getErrorMessageFromServer';
import { productService } from 'services/product/product.service';
import {
  IAddProductReviewRequest,
  IBreadCrumbsRequest,
  IPopularProductRequest,
  IProductRequest,
  IProductReviewRequest,
} from 'services/product/product.serviceTypes';
import { setResponseNotice } from 'store/reducers/appSlice/slice';
import { IBrandsInfo } from 'store/reducers/supplier/product/types';

export const getProductById = createAsyncThunk<
  IResponseGetProductCardId,
  IProductRequest
>('product/getProductById', async (payload, { rejectWithValue }) => {
  try {
    return productService.getProductById(payload);
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data);
    }

    return rejectWithValue('[Error]: getProductById');
  }
});

export const getAllBrands = createAsyncThunk<IBrandsInfo[], void>(
  'brands/getAllBrands',
  async (_, { rejectWithValue }) => {
    try {
      const response = await productService.getBrandsInfo();

      return response;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue('[Error]: getAllBrands');
    }
  },
);

export const getImagesByProductId = createAsyncThunk<IImage[], IProductRequest>(
  'product/getImagesByProductId',
  async ({ product_id }, { rejectWithValue }) => {
    try {
      return await productService.getProductImagesById({ product_id });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue('[Error]: getImagesByProductId');
    }
  },
);

export const addFavoriteProduct = createAsyncThunk<any, IProductRequest>(
  'favorite/addFavoriteProduct',
  async (payload, { rejectWithValue }) => {
    try {
      const result = await productService.addFavorite(payload);

      return {
        is_favorite: result,
        product_id: payload.product_id,
      };
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue('[Error]: addFavoriteProduct');
    }
  },
);

export const removeFavoriteProduct = createAsyncThunk<any, IProductRequest>(
  'favorite/removeFavoriteProduct',
  async (payload, { rejectWithValue }) => {
    try {
      const result = await productService.removeFavorite(payload);

      return {
        is_favorite: result,
        product_id: payload.product_id,
      };
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue('[Error]: removeFavoriteProduct');
    }
  },
);

export const getPopularProducts = createAsyncThunk<[], IPopularProductRequest>(
  'popularProducts/getPopularProducts',
  async (payload, { rejectWithValue }) => {
    try {
      const { result } = await productService.getPopularProduct(payload);

      return result;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue('[Error]: getPopularProductsById');
    }
  },
);

export const getBreadCrumbs = createAsyncThunk<any, IBreadCrumbsRequest>(
  'product/getBreadCrumbs',
  async (productData, { rejectWithValue }) => {
    try {
      const response = await productService.getBreadCrumbs(productData);

      return {
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue('[getBreadCrumbs]: ERROR');
    }
  },
);

export const addFavoriteProductPage = createAsyncThunk<any, IProductRequest>(
  'favorite/addFavoriteProductPage',
  async (payload, { rejectWithValue }) => {
    try {
      return await productService.addFavorite(payload);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue('[Error]: addFavoriteProduct');
    }
  },
);

export const removeFavoriteProductPage = createAsyncThunk<any, IProductRequest>(
  'favorite/removeFavoriteProductPage',
  async (payload, { rejectWithValue }) => {
    try {
      return !(await productService.removeFavorite(payload));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue('[Error]: removeFavoriteProduct');
    }
  },
);

export const getProductsReviewsById = createAsyncThunk<
  IResponseGetProductReviews,
  IProductReviewRequest
>(
  'product/getProductsReviewsById',
  async ({ product_id, grade, offset, limit, with_photos }, { rejectWithValue }) => {
    try {
      return await productService.getProductsReviewsById({
        product_id,
        grade,
        offset,
        limit,
        with_photos,
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue('[Error]: getProductsReviewsById');
    }
  },
);

export const addProductReview = createAsyncThunk<any, IAddProductReviewRequest>(
  'product/addProductReview',
  async (
    { product_id, product_review_photo, product_review_text, product_review_grade },
    { dispatch, rejectWithValue },
  ) => {
    try {
      return await productService.addProductReview({
        product_id,
        product_review_photo,
        product_review_text,
        product_review_grade,
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setResponseNotice({
            noticeType: 'error',
            message: getErrorMessageFromServer(error),
          }),
        );
      }

      return rejectWithValue('[Error]: getProductsReviewsById');
    }
  },
);

export const getReviewGradesByProductId = createAsyncThunk<
  IProductReviewsGrades,
  IProductRequest
>('product/getReviewGradesByProductId', async ({ product_id }, { rejectWithValue }) => {
  try {
    return await productService.getReviewGradesByProductId({
      product_id,
    });
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data);
    }

    return rejectWithValue('[Error]: getReviewGradesByProductId');
  }
});
