import { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { ProductCardFull } from './ProductCardFull/ProductCardFull';

import { Filter } from 'assets/icons';
import { useAppDispatch, useAppSelector, useMediaQuery } from 'common/hooks';
import { ICategory, LoadingStatusEnum, SelectedViewEnum } from 'common/types';
import { PageViewSwitcher, ProductsPerPage, SkeletonProductCard } from 'elements';
import { ProductCard } from 'modules';
import { ICategoryRequest } from 'services/product/product.serviceTypes';
import {
  productsListSelector,
  productsPerPageSelector,
  setProductsPerPage,
  totalProductsCountSelector,
} from 'store/reducers/productSlice';
import { getProductsListAC } from 'store/reducers/productSlice/sagas';
import { loadingProductsSelector } from 'store/reducers/productSlice/selectors';
import { getBreadCrumbs } from 'store/reducers/productSlice/thunks';
import { ISortBy, ISortField } from 'store/reducers/productSlice/types';
import { BreadCrumbs, ButtonQuestion } from 'ui-kit';
import { Pagination } from 'ui-kit/Pagination/Pagination';

import style from './ProductList.module.scss';

interface IProductList {
  currentSortField: ISortField;
  currentSortBy: ISortBy;
  closeModal: (value: boolean) => void;
  showModal: boolean;
}

const DESIRED_BREAKPOINT = 430;

export const ProductList: FC<IProductList> = ({
  currentSortField,
  currentSortBy,
  showModal,
  closeModal,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedView, setSelectedView] = useState<SelectedViewEnum>(
    SelectedViewEnum.GRID,
  );
  const loadingSlider = useAppSelector(loadingProductsSelector);
  const productsPerPage = useAppSelector(productsPerPageSelector);
  const totalCount = useAppSelector(totalProductsCountSelector);
  const products = useAppSelector(productsListSelector);
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get('category_id');
  const query = searchParams.get('search') || '';
  const totalPages = Math.ceil(totalCount / productsPerPage);
  const { isDevice } = useMediaQuery(DESIRED_BREAKPOINT);
  const [breadCrumbs, setBreadCrumbs] = useState<ICategory[]>([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [currentPage]);

  useEffect(() => {
    const params = {
      offset: (currentPage - 1) * productsPerPage || 0,
      limit: productsPerPage,
      category_id: category_id || 1,
      sort: currentSortField,
      ascending: currentSortBy === 'asc',
      query,
    } as ICategoryRequest;

    dispatch(getProductsListAC(params));
    dispatch(getBreadCrumbs({ category_id: category_id || '1' })).then(data => {
      if (data.payload.data) {
        const breadCrumbsData = data.payload.data.map(
          (el: { name: string; id: number }) => {
            return { name: el.name, id: el.id };
          },
        );

        setBreadCrumbs(breadCrumbsData);
      }
    });
  }, [productsPerPage, currentPage, category_id, currentSortField, currentSortBy, query]);
  const handleChangeSelect = (value: number): void => {
    dispatch(setProductsPerPage(value));
  };

  const modsProductsContainer = {
    [style.grid_container]: selectedView === SelectedViewEnum.GRID || isDevice,
    [style.list_container]: selectedView === SelectedViewEnum.LIST,
  };

  const isLoading = loadingSlider === LoadingStatusEnum.Loading;

  const paginationComponent = (
    <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChanged={setCurrentPage}
      disabled={isLoading}
    />
  );

  const productsChunkAmount =
    totalPages !== currentPage
      ? productsPerPage
      : totalCount - (totalPages - 1) * productsPerPage;

  const productSkeleton = Array.from({
    length: productsChunkAmount - products.length,
  }).map((el, i) => <SkeletonProductCard key={i} selectedView={selectedView} />);

  const productsView = products?.map(product => {
    if (selectedView === SelectedViewEnum.LIST) {
      if (!isDevice) {
        return <ProductCardFull key={product.id} product={product} />;
      }

      return <ProductCard key={product.id} product={product} />;
    }

    return <ProductCard key={product.id} product={product} />;
  });

  return (
    <div className={style.wrapper}>
      <div className={style.control_panel}>
        <div className={style.view_switchers}>
          <PageViewSwitcher
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          <div className={style.branch_crumbs}>
            <BreadCrumbs breadCrumbs={breadCrumbs} />
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => closeModal(true)}
            onKeyDown={() => closeModal(true)}
            className={style.filter}
          >
            <Filter className={showModal ? style.filter_icon : undefined} />
          </div>
          {/* TODO (fake data) */}
        </div>
        {paginationComponent}
      </div>

      <div className={cn(style.list, modsProductsContainer)}>
        {productsView} {productSkeleton}
      </div>
      <div className={style.control_panel}>
        <ProductsPerPage disabled={isLoading} onChange={handleChangeSelect} />
        {paginationComponent}
      </div>
      <ButtonQuestion />
    </div>
  );
};
