import { FC, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Brand } from './Brand/Brand';
import { FilterComponent } from './FilterComponent/FilterComponent';
import { Filters } from './Filters/Filters';
import { Price } from './Price/Price';

import { ICategoryData } from 'store/reducers/productSlice/types';
import { Button } from 'ui-kit';

import style from './ProductFilter.module.scss';

interface IProductFilter {
  onSaveQueryParams: (selectedFilters: Record<string, string[]>) => void;
  onResetAllFilters: () => void;
  categoryData: ICategoryData;
}

export const ProductFilter: FC<IProductFilter> = ({
  onResetAllFilters,
  onSaveQueryParams,
  categoryData,
}): JSX.Element => {
  const location = useLocation();
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({
    brands: [],
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filters: Record<string, string[]> = {};

    params.forEach((value, key) => {
      filters[key] = value.split(',');
    });
    setSelectedFilters(filters);
  }, [location.search]);

  const handleFilterChange = (filterName: string, selectedValues: string[]): void => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleApplyFilters = (): void => {
    const params = new URLSearchParams();

    Object.entries(selectedFilters).forEach(([key, values]) => {
      if (values.length > 0) {
        params.set(key, values.join(','));
      }
    });
    window.history.pushState({}, '', `${location.pathname}?${params.toString()}`);
    onSaveQueryParams(selectedFilters);
  };

  return (
    <div className={style.product_filter}>
      <div className={style.filters_container}>
        <Filters onResetAllFilters={onResetAllFilters} />
        {/* <Price /> */}
        <Brand
          selectedBrands={selectedFilters.brands || []}
          onSelectBrandsChange={brands => handleFilterChange('brands', brands)}
        />
        {categoryData.properties.map(property => (
          <FilterComponent
            key={property.id}
            title={property.name}
            items={property.values.map(v => v.value)}
            selectedItems={selectedFilters[property.name] || []}
            onSelectItemsChange={selectedValues =>
              handleFilterChange(property.name, selectedValues)
            }
          />
        ))}
        {categoryData.variations.map(variation => (
          <FilterComponent
            key={variation.id}
            title={variation.name.includes('Color') ? 'Color' : 'Sizes'}
            items={variation.values.map(v => v.value)}
            selectedItems={selectedFilters[variation.name] || []}
            onSelectItemsChange={selectedValues =>
              handleFilterChange(variation.name, selectedValues)
            }
            isVariation
          />
        ))}
      </div>
      <Button onClick={handleApplyFilters} className={style.button}>
        Apply
      </Button>
    </div>
  );
};
