import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { ProductFilter } from './ProductFilter/ProductFilter';
import { ProductList } from './ProductList/ProductList';

import { useAppDispatch, useAppSelector, useMediaQuery } from 'common/hooks';
import Modal from 'elements/Modal';
import { productService } from 'services/product/product.service';
import { setResetAllFilters, setSortBy, setSortField } from 'store/reducers/productSlice';
import { sortBySelector, sortFieldSelector } from 'store/reducers/productSlice/selectors';
import { initView } from 'store/reducers/productSlice/slice';
import { ICategoryData, ISortBy, ISortField } from 'store/reducers/productSlice/types';

import style from './ProductListPage.module.scss';

export const ProductListPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortField = useAppSelector(sortFieldSelector);
  const sortBy = useAppSelector(sortBySelector);
  const currentSortField = searchParams.get('sortField') || 'rating';
  const currentSortBy = searchParams.get('sortBy') || 'desc';
  const category_id = searchParams.get('category_id');
  const [isModalOpen, setModalOpen] = useState(false);
  const { isDevice } = useMediaQuery();
  const [categoryData, setCategoryData] = useState<ICategoryData>({
    properties: [],
    variations: [],
  });

  const getCategoryData = async (categoryId: string | null): Promise<void> => {
    if (categoryId) {
      const properties = await productService.getCategoryProperties(categoryId);
      const variations = await productService.getCategoryVariations(categoryId);

      setCategoryData({ properties, variations });
    }
  };

  useEffect(() => {
    dispatch(setSortField(currentSortField as ISortField));
    dispatch(setSortBy(currentSortBy as ISortBy));
    dispatch(initView());
    getCategoryData(category_id);
  }, [category_id, dispatch, currentSortField, currentSortBy]);

  const handleResetAllFilters = (): void => {
    dispatch(setResetAllFilters());

    setSearchParams('');
  };

  const handleSaveQueryParams = (selectedFilters: Record<string, string[]>): void => {
    const params = new URLSearchParams(searchParams);

    params.set('sortField', sortField);
    params.set('sortBy', sortBy);

    if (category_id) {
      params.set('category_id', category_id);
    }

    Object.entries(selectedFilters).forEach(([key, values]) => {
      if (values.length > 0) {
        params.set(key, values.join(','));
      } else {
        params.delete(key);
      }
    });

    setSearchParams(params);
  };

  return (
    <div className={style.product_list_page}>
      {!isDevice ? (
        <ProductFilter
          onSaveQueryParams={handleSaveQueryParams}
          onResetAllFilters={handleResetAllFilters}
          categoryData={categoryData}
        />
      ) : (
        <Modal showModal={isModalOpen} closeModal={setModalOpen}>
          <ProductFilter
            onSaveQueryParams={handleSaveQueryParams}
            onResetAllFilters={handleResetAllFilters}
            categoryData={categoryData}
          />
        </Modal>
      )}

      <ProductList
        closeModal={setModalOpen}
        showModal={isModalOpen}
        currentSortField={currentSortField as ISortField}
        currentSortBy={currentSortBy as ISortBy}
      />
    </div>
  );
};
