import React, { FC, useState } from 'react';

import { FilterTag } from 'pages/general-pages/ProductListPage/ProductFilter/FilterTag/FilterTag';
import { Search } from 'ui-kit/Search/Search';

import style from './FilterComponent.module.scss';

interface IPropsFilter {
  title: string;
  items: string[];
  selectedItems: string[];
  onSelectItemsChange: (items: string[]) => void;
  isVariation?: boolean;
}

export const FilterComponent: FC<IPropsFilter> = ({
  title,
  items,
  selectedItems,
  onSelectItemsChange,
  isVariation = false,
}) => {
  const [itemsToShow, setItemsToShow] = useState<string[]>(items);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSelectItem = (item: string): void => {
    const updatedItems = selectedItems.includes(item)
      ? selectedItems.filter(i => i !== item)
      : [...selectedItems, item];

    onSelectItemsChange(updatedItems);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const searchItem = e.target.value;

    setSearchTerm(searchItem);

    const filteredItems = items.filter(item =>
      item.toLowerCase().includes(searchItem.toLowerCase()),
    );

    setItemsToShow(filteredItems);
  };

  return (
    <div className={style.container}>
      <p className={style.title}>{title}</p>
      <div>
        {!isVariation && (
          <Search placeholder="Search" value={searchTerm} onChange={handleSearch} />
        )}
        <div
          className={
            !isVariation
              ? style.tags_properties_container
              : style.tags_variations_container
          }
        >
          {searchTerm === ''
            ? items.map(item => (
                <FilterTag
                  key={item}
                  title={item}
                  selected={selectedItems.includes(item)}
                  onClick={() => handleSelectItem(item)}
                  isVariation={isVariation}
                />
              ))
            : itemsToShow.map(item => (
                <FilterTag
                  key={item}
                  title={item}
                  selected={selectedItems.includes(item)}
                  onClick={() => handleSelectItem(item)}
                  isVariation={isVariation}
                />
              ))}
        </div>
      </div>
    </div>
  );
};
