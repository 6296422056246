import { put, takeEvery } from 'redux-saga/effects';

import {
  getSupplierProducts,
  getSupplierProductsStatus,
  resetSupplierProducts,
} from './supplierProductSlice';
import { IProductRequestParams } from './types';

import { getCookie } from 'common/utils';
import { setResponseNotice } from 'store/reducers/appSlice/slice';

export function* getSupplierProductsSaga(
  action: ReturnType<typeof getSupplierProductsListAC>,
): Generator<unknown, any, any> {
  const { offset, limit, sort, ascending, category_ids, is_active, query, on_sale } =
    action.params;
  const baseURL = process.env.REACT_APP_SERVER_URL || 'http://localhost/';

  yield put(getSupplierProductsStatus(true));
  yield put(resetSupplierProducts());

  const response = yield fetch(
    `${baseURL}suppliers/products?offset=${offset}&limit=${limit}&sort=${sort}&ascending=${ascending}&category_ids=${category_ids}&is_active=${is_active}&on_sale=${on_sale}&query=${query}`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': getCookie('csrf_access_token') || '',
      },
      credentials: 'include',
    },
  );

  if (!response.body) {
    throw new Error('ReadableStream not supported');
  }

  let buffer = '';

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  while (true) {
    const { done, value } = yield reader.read();

    if (done) {
      break;
    }

    const chunk = decoder.decode(value, { stream: true });

    buffer += chunk;
    let newlineIndex;

    // eslint-disable-next-line no-cond-assign
    while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
      const line = buffer.slice(0, newlineIndex);

      buffer = buffer.slice(newlineIndex + 1);

      if (line.trim()) {
        try {
          const message = JSON.parse(line);

          if (!message.ok) {
            yield put(
              setResponseNotice({
                noticeType: 'error',
                message: 'Stream message indicates error',
              }),
            );
          }
          if (message.type === 'meta' || message.type === 'product') {
            yield put(getSupplierProducts(message.result));
          }
          if (message.type === 'end') {
            yield put(getSupplierProductsStatus(false));
          }
        } catch (e) {
          yield put(getSupplierProductsStatus(false));
        }
      }
    }
  }

  if (buffer.trim()) {
    try {
      const message = JSON.parse(buffer);

      yield put(
        setResponseNotice({
          noticeType: 'error',
          message: message.detail[0].msg || message.detail,
        }),
      );
    } catch (e) {
      yield put(
        setResponseNotice({
          noticeType: 'error',
          message: 'Error parsing remaining buffer:',
        }),
      );
      yield put(getSupplierProductsStatus(false));
    }
  }

  return {
    products: response,
  };
}

export const getSupplierProductsListAC = (params: IProductRequestParams) =>
  ({
    type: 'suppliers/getSupplierProducts',
    params,
  } as const);

export function* watchSupplierProductsList(): any {
  yield takeEvery('suppliers/getSupplierProducts', getSupplierProductsSaga);
}
