import { FC } from 'react';

import cn from 'classnames';

import style from './FilterTag.module.scss';

interface IFilterTag {
  title: string;
  selected: boolean;
  onClick: () => void;
  isVariation?: boolean;
}

export const FilterTag: FC<IFilterTag> = ({
  title,
  selected,
  onClick,
  isVariation = false,
}): JSX.Element => {
  return (
    <div className={isVariation ? style.variations_tag_container : ''}>
      <div
        className={cn(!isVariation ? style.properties_tag : style.variations_tag, {
          [style.active]: selected,
        })}
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick();
          }
        }}
        tabIndex={0}
        role="button"
      >
        {title}
      </div>
    </div>
  );
};
