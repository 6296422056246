import { put, takeEvery } from 'redux-saga/effects';

import {
  getProductsCompilationStatus,
  getProductsList,
  getProductsListStatus,
  resetProductsCompilation,
  resetProductsList,
  getProductsCompilation,
} from './slice';
import { IResponseProducts } from './types';

import { LoadingStatusEnum } from 'common/types';
import { getCookie } from 'common/utils';
import { ICategoryRequest } from 'services/product/product.serviceTypes';
import { setResponseNotice } from 'store/reducers/appSlice/slice';

export function* getProductsListSaga(
  action: ReturnType<typeof getProductsListAC>,
): Generator<unknown, IResponseProducts, any> {
  const { sort, ascending, category_id, offset, limit, query } = action.params;
  const baseURL = process.env.REACT_APP_SERVER_URL || 'http://localhost/';
  const options = {
    method: 'GET',
    headers: {
      'X-CSRF-Token': getCookie('csrf_access_token') || '',
    },
    withCredentials: true,
  };

  yield put(getProductsListStatus(LoadingStatusEnum.Loading));
  yield put(resetProductsList());

  const response = yield fetch(
    `${baseURL}products?offset=${offset}&limit=${limit}&sort=${sort}&ascending=${ascending}&category_ids=${category_id}&on_sale=all&query=${query}`,
    options,
  );

  if (!response.body) {
    throw new Error('ReadableStream not supported');
  }

  let buffer = '';

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  while (true) {
    const { done, value } = yield reader.read();

    if (done) {
      break;
    }

    const chunk = decoder.decode(value, { stream: true });

    buffer += chunk;

    let newlineIndex;

    // eslint-disable-next-line no-cond-assign
    while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
      const line = buffer.slice(0, newlineIndex);

      buffer = buffer.slice(newlineIndex + 1);

      if (line.trim()) {
        try {
          const message = JSON.parse(line);

          if (!message.ok) {
            yield put(
              setResponseNotice({
                noticeType: 'error',
                message: 'Stream message indicates error',
              }),
            );
          }
          if (message.type === 'meta' || message.type === 'product') {
            yield put(getProductsList(message.result));
          }
          if (message.type === 'end') {
            yield put(getProductsListStatus(LoadingStatusEnum.Success));
          }
        } catch (e) {
          yield put(getProductsListStatus(LoadingStatusEnum.Failed));
        }
      }
    }
  }

  if (buffer.trim()) {
    try {
      const message = JSON.parse(buffer);

      yield put(
        setResponseNotice({
          noticeType: 'error',
          message: message.detail[0].msg || message.detail,
        }),
      );
    } catch (e) {
      yield put(
        setResponseNotice({
          noticeType: 'error',
          message: 'Error parsing remaining buffer:',
        }),
      );
      yield put(getProductsListStatus(LoadingStatusEnum.Failed));
    }
  }

  return {
    products: response,
  };
}

export function* getProductsCompilationSaga(
  action: ReturnType<typeof getProductsCompilationAC>,
): Generator<unknown, IResponseProducts, any> {
  const { sort, ascending, category_id, offset, limit, query } = action.params;
  const baseURL = process.env.REACT_APP_SERVER_URL || 'http://localhost/';
  const options = {
    method: 'GET',
    headers: {
      'X-CSRF-Token': getCookie('csrf_access_token') || '',
    },
    withCredentials: true,
  };

  yield put(getProductsCompilationStatus(LoadingStatusEnum.Loading));

  const response = yield fetch(
    `${baseURL}products?offset=${offset}&limit=${limit}&sort=${sort}&ascending=${ascending}&category_ids=${category_id}&on_sale=all&query=${
      query || ''
    }`,
    options,
  );

  if (!response.body) {
    throw new Error('ReadableStream not supported');
  }

  let buffer = '';

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  while (true) {
    const { done, value } = yield reader.read();

    if (done) {
      break;
    }

    const chunk = decoder.decode(value, { stream: true });

    buffer += chunk;

    let newlineIndex;

    // eslint-disable-next-line no-cond-assign
    while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
      const line = buffer.slice(0, newlineIndex);

      buffer = buffer.slice(newlineIndex + 1);

      if (line.trim()) {
        try {
          const message = JSON.parse(line);

          if (!message.ok) {
            yield put(
              setResponseNotice({
                noticeType: 'error',
                message: 'Stream message indicates error',
              }),
            );
          }
          if (message.type === 'meta' || message.type === 'product') {
            yield put(getProductsCompilation(message.result));
          }
          if (message.type === 'end') {
            yield put(getProductsCompilationStatus(LoadingStatusEnum.Success));
          }
        } catch (e) {
          yield put(getProductsCompilationStatus(LoadingStatusEnum.Failed));
        }
      }
    }
  }

  if (buffer.trim()) {
    try {
      const message = JSON.parse(buffer);

      yield put(
        setResponseNotice({
          noticeType: 'error',
          message: message.detail[0].msg || message.detail,
        }),
      );
    } catch (e) {
      yield put(
        setResponseNotice({
          noticeType: 'error',
          message: 'Error parsing remaining buffer:',
        }),
      );
      yield put(getProductsCompilationStatus(LoadingStatusEnum.Failed));
    }
  }

  return {
    products: response,
  };
}

export function* resetProductsCompilationSaga(): Generator<unknown, void, any> {
  yield put(resetProductsCompilation());
}

export const getProductsListAC = (params: ICategoryRequest) =>
  ({
    type: 'product/getProductsList',
    params,
  } as const);

export const getProductsCompilationAC = (params: ICategoryRequest) =>
  ({
    type: 'product/getProductsCompilation',
    params,
  } as const);

export const resetProductsCompilationAC = () =>
  ({
    type: 'product/resetProductsCompilation',
  } as const);

export function* watchProductsList(): any {
  yield takeEvery('product/getProductsList', getProductsListSaga);
  yield takeEvery('product/getProductsCompilation', getProductsCompilationSaga);
  yield takeEvery('product/resetProductsCompilation', resetProductsCompilationSaga);
}
