import { DetailedHTMLProps, FC, HTMLAttributes, ReactElement, useState } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { amountRange } from './helper/amountRange';

import ProductImage from 'elements/ProductImage/ProductImage';
import { PRODUCT_DETAILS } from 'routes';
import { IProduct } from 'store/reducers/productSlice/types';
import { Stars } from 'ui-kit';

import style from './ProductCard.module.scss';

interface IProductCard
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  product: IProduct;
  isFavorite?: boolean;
}
export const ProductCard: FC<IProductCard> = ({
  product,
  isFavorite,
  className,
  ...restProps
}): ReactElement => {
  const {
    id,
    name,
    min_quantity,
    product_variation_price_from,
    product_variation_price_to,
    images,
    grade_average,
    is_favorite = false,
  } = product;

  const image_url = images && Object.values(images[0]?.image_url)[0];
  const pathToProduct = `${PRODUCT_DETAILS}/${id}`;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <article
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={cn(style.card, className)}
      {...restProps}
    >
      <Link tabIndex={-1} to={pathToProduct} className={style.link}>
        <ProductImage
          isHovered={isHovered}
          imageUrl={image_url || ''}
          name={name}
          productId={id}
          isFavorite={isFavorite || is_favorite}
        />
        <div className={style.direction}>
          <h4 className={style.card_title}>{name}</h4>
        </div>
        <div className={style.price}>
          <span className={style.amount}>
            {amountRange(product_variation_price_from, product_variation_price_to)}/pc
          </span>
          <span className={style.rating}>{`/from ${min_quantity} pcs`}</span>
        </div>
        <Stars reward={grade_average || 0} />
      </Link>
    </article>
  );
};
