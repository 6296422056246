import { DetailedHTMLProps, FC, HTMLAttributes, useState } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import cn from 'classnames';

import { MagnifierLightGreyIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { FavoriteButton } from 'elements/FavoriteButton/FavoriteButton';
import { LazyImage } from 'elements/LazyImage/LazyImage';
import { userRoleSelector } from 'store/reducers/authSlice';
import { addFavoriteProduct, removeFavoriteProduct } from 'store/reducers/productSlice';

import style from './ProductImage.module.scss';

interface IProductCard
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  name: string;
  imageUrl: string;
  productId?: number;
  isFavorite: boolean;
  isHovered?: boolean;
}

const ProductImage: FC<IProductCard> = ({
  className,
  name,
  imageUrl,
  productId,
  isHovered,
  isFavorite,
  ...restProps
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(userRoleSelector);

  const [isProductFavorite, setIsProductFavorite] = useState(isFavorite);

  const handleChangeFavorite = (isProductFavorite: boolean): void => {
    if (isProductFavorite) {
      dispatch(addFavoriteProduct({ product_id: Number(productId) }));

      if (isFulfilled(addFavoriteProduct)) {
        setIsProductFavorite(true);
      }
    } else {
      dispatch(removeFavoriteProduct({ product_id: Number(productId) }));

      if (isFulfilled(removeFavoriteProduct)) {
        setIsProductFavorite(false);
      }
    }
  };

  return (
    <div
      className={cn(style.image_wrapper, className, {
        [style.hover_visible]: isHovered,
      })}
      {...restProps}
    >
      {userRole && (
        <FavoriteButton
          isFavorite={isProductFavorite}
          onChange={handleChangeFavorite}
          className={style.flag}
        />
      )}
      <LazyImage
        src={imageUrl || ''}
        alt={name}
        className={style.image}
        type="product_image_user"
      />
      <div className={style.hover}>
        <div className={style.hover_text}>
          <MagnifierLightGreyIcon />
          <span>View more</span>
        </div>
      </div>
    </div>
  );
};

export default ProductImage;
